/* eslint no-console:0 */
// Entry point for the build script in your package.json

import Rails from "rails-ujs";
Rails.start();

import Turbolinks from "turbolinks";
Turbolinks.start();

import $ from "jquery";
window.$ = $;
window.jQuery = $;

import autosize from "autosize";
import Dropzone from "dropzone";



function debounce(fn, delay) {
    var timer = null;
    return function() {
        var context = this,
            args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function() {
            fn.apply(context, args);
        }, delay);
    };
}

document.addEventListener("turbolinks:load", function() {
    // datalist_for("#contributor_article_id","/articles.json", "#articles");
    datalist_for("#contributor_author_id", "/authors.json", "#authors");
    autosize(document.querySelectorAll('textarea'));
});

function datalist_for(field, url, dl) {
    if (document.createElement("datalist").options) {
        $(field).on(
            "keyup",
            debounce(function(e) {
                var val = $(this).val();
                if (val === "") return;
                $.get(
                    url, {
                        term: val,
                    },
                    function(res) {
                        var dataList = $(dl);
                        dataList.empty();
                        if (res.length) {
                            for (var i = 0, len = res.length; i < len; i++) {
                                var opt = $("<option></option>").attr(
                                    "value",
                                    res[i]["json_name"]
                                );
                                dataList.append(opt);
                            }
                        }
                    },
                    "json"
                );
            }, 250)
        );
    }
}

function uppy_for(upload_url, instructions, done_func) {
    if (typeof uppy !== "undefined") {
        // if (uppy) {
        uppy.close();
        uppy = null;
    }
    window.uppy = Uppy.Core({
        debug: false,
        autoProceed: true,
        locale: Uppy.locales.nl_NL,
    });
    uppy.use(Uppy.DragDrop, {
        target: ".UppyDragDrop",
        locale: {
            strings: {
                dropHereOr: instructions,
            },
        },
    });
    uppy.use(Uppy.XHRUpload, {
        endpoint: upload_url,
        formData: true,
        fieldName: "upload[file]",
    });
    uppy.use(Uppy.ProgressBar, {
        target: "body",
        fixed: true,
        hideAfterFinish: true,
    });

    uppy.on("upload-success", done_func);
}

window.uppy_for = uppy_for;

import { Elm } from "../assets/builds/elm.js"

document.addEventListener('DOMContentLoaded', () => {
    const $hpb = document.getElementById("homepage_builder");
    if ($hpb) {
        Elm.HomepageBuilder.init({
            node: $hpb,
            flags: {
                id: $hpb.dataset.id,
                url: $hpb.dataset.url,
                csrf: document.querySelector('meta[name=csrf-token]').attributes.content
                    .value,
            }
        });
    }
});

document.addEventListener('turbolinks:load', () => {
    const $hpb = document.getElementById("mediaUrls");
    if ($hpb) {
        Elm.MediaUrls.init({
            node: $hpb,
            flags: {
                id: $hpb.dataset.id,
                media_urls: JSON.parse($hpb.dataset.mediaUrls),
                csrf: document.querySelector('meta[name=csrf-token]').attributes.content
                    .value,
            }
        });
    }
});
